<template>
  <aside class="generalFeedback" v-if="showing">
    <span class="generalFeedback__dismiss" tabindex="0" @click="dismiss">
      <i class="fa-duotone fa-xmark"></i>
    </span>
    <h1 class="generalFeedback__title">
      {{ $t("titlegeneral") }}
    </h1>
    <div class="generalFeedback__smileyContainer">
      <GeneralFeedbackSmiley
        v-for="smiley in 5"
        :key="smiley"
        :value="smiley"
        @change="getSmileyEvent"
        :active="value === smiley"
      />
    </div>
    <label for="generalFeedback__feedback">
      <span class="generalFeedback__subtitle">
        {{ $t("moreFeedback") }}
      </span>
      <textarea
        v-model="feedback"
        name="generalFeedback__feedback"
        class="generalFeedback__feedback"
        id="generalFeedback__feedback"
        rows="5"
      />
    </label>
    <button class="pose__button" @click="sendFeedback()">
      {{ $t("feedbackButton") }}
    </button>
  </aside>
  <Toast />
</template>

<script>
import { defineComponent } from 'vue';
import Toast from 'primevue/toast';
import { getRandomNumberBetweenRange } from '@/app/shared/utils/utils';
import { mapState } from 'vuex';
import GeneralFeedbackSmiley from './GeneralFeedbackSmiley.vue';
import feedbackApi from '../feedback.api';
import browserUserData from '../services/BrowserUserData';
import modalsMixin from '../../../shared/mixins/modalsMixin';

const viewPercentage = 15;
const hoursOff = getRandomNumberBetweenRange(6, 9) * 30 * 24; // entre 6 y 9 meses.

export default defineComponent({
  name: 'GeneralFeedback',
  mixins: [modalsMixin],
  data() {
    return {
      feedback: '',
      value: 0,
      sentOk: false,
      hasError: false,
      showable: true,
      modal: {
        name: 'feedback',
        viewPercentage,
        hoursOff,
      },
    };
  },
  computed: {
    ...mapState('feedback', ['lastBeaconService']),
  },
  components: {
    GeneralFeedbackSmiley,
    Toast,
  },
  methods: {
    getSmileyEvent(smileyValue) {
      this.value = smileyValue;
    },
    dismiss() {
      this.showable = false;
      this.closeModal();
    },
    async sendFeedback() {
      try {
        const { roles, userAgent } = browserUserData(navigator, window);

        const ratingUrl = this.$route.fullPath;
        // Rating tool -> si es el home micampus, generalFeedback
        let ratingTool = ratingUrl === '/' ? 'generalFeedback' : null;
        // Si estamos en la ruta (o una hija) de la última llamada beacon, se coge el identificador
        if (this.lastBeaconService) {
          const beaconUrlSplits = this.lastBeaconService?._link?.external?.href?.split('#');
          if (
            beaconUrlSplits
            && beaconUrlSplits.length === 2
            && this.$route.fullPath.startsWith(beaconUrlSplits[1])
          ) {
            ratingTool = this.lastBeaconService.identifier;
          }
        }

        this.sentOk = await feedbackApi.sendFeedback({
          ratingTool,
          ratingUrl,
          ratingComment: this.feedback,
          ratingValue: `${this.value}`,
          ratingProperties: {
            relations: roles,
            platform: userAgent,
          },
        });
        this.$toast.add({
          severity: 'success',
          summary: this.$t('successMessage1'),
          detail: this.$t('successMessage2'),
          life: 3000,
        });
      } catch (error) {
        this.hasError = true;
        this.$toast.add({
          severity: 'error',
          summary: this.$t('errorMessage1'),
          detail: this.$t('errorMessage2'),
          life: 3000,
        });
      } finally {
        localStorage.setItem('timeDismissed', new Date().getTime());
        this.showable = false;
        this.closeModal();
      }
    },
  },
});
</script>

<i18n src="../locales/i18nSearchFeedback.json"></i18n>

<style lang="scss" scoped>
.generalFeedback {
  background: var(--privatebgcolor);
  border-radius: var(--card-border-radius);
  margin: 2rem 1.25rem 0 1.25rem;
  padding: 0.25rem 1rem;
  z-index: 10;
  bottom: 1.5rem;
  box-shadow: var(--shadowLevel-3);
  margin: 0 0;
  position: fixed;
  right: 1.5rem;
  @media (min-width: $screen-sm-min) {
    width: 25rem;
  }
  @media (max-width: $screen-sm-min) {
    left: 1.5rem;
  }

  &__dismiss {
    cursor: pointer;
    float: right;
    font-size: 1.25rem;
    margin: 0.15rem -0.5rem 0 0;
    text-align: center;
    width: 1.5rem;
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.15rem;
    margin: 1rem 0 0.75rem 0;
    padding: 0 0 0.5rem 0;
  }
  &__subtitle {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1rem;
    display: block;
    margin: 1rem 0 0.5rem 0;
    padding: 0 0 0;
  }
  &__thumb {
    font-size: 3rem;
  }
  &__feedback {
    border-radius: var(--card-border-radius);
    border: 1px solid var(--border-color);
    display: block;
    padding: 0.5rem;
    margin: 0 0 1rem 0;
    width: 100%;
  }
  &__smileyContainer {
    margin: -0.75rem auto -0.75rem auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
</style>
