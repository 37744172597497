import { defineAsyncComponent } from 'vue';

const MisRecibos = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-MisRecibosView" */'./views/MisRecibosView.vue'));
const NuevoRecibo = defineAsyncComponent(() => import(/* webpackChunkName: "MisRecibos-NuevoReciboView" */'./views/NuevoReciboView.vue'));
const FinDevolucion = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-solicitudCorrecta" */'./views/FinDevolucionView.vue'));
const Devolucion = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-devolucionIngreso" */'./views/DevolucionView.vue'));

const rutaProyecto = '/mis-recibos';

export default [
  {
    path: `${rutaProyecto}`,
    name: 'MisRecibos',
    component: MisRecibos,
    meta: {
      main: true,
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
  {
    path: `${rutaProyecto}/nuevo`,
    name: 'MisRecibos-NuevoRecibo',
    component: NuevoRecibo,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
  // Devolución
  {
    path: `${rutaProyecto}/devolucion`,
    name: 'MisRecibos-Devolucion',
    component: Devolucion,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
  {
    path: `${rutaProyecto}/devolucion/fin`,
    name: 'MisRecibos-FinDevolucion',
    component: FinDevolucion,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
  {
    path: `${rutaProyecto}/devolucion/error`,
    name: 'MisRecibos-FinDevolucionError',
    component: FinDevolucion,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-recibos',
    },
  },
];
