<template>
  <nav class="sideMenu">
    <span
      class="sideMenu__button is-clickable"
      v-if="showLess && canCarousel"
      @click="activate()"
      :title="$t('showless')"
    >
      <i class="fa-solid fa-down-left-and-up-right-to-center" />
      <span class="sr-only">{{ $t('showless') }}</span>
    </span>
    <span
      class="sideMenu__button is-clickable"
      v-if="showMore && canCarousel"
      :title="$t('showmore')"
      @click="deActivate()"
    >
      <i class="fas fa-expand-alt" />
      <span class="sr-only">{{ $t('showmore') }}</span>
    </span>
    <span
      class="sideMenu__button is-movable"
      v-if="isLogged && !isMobile()"
    >
      <i class="fas fa-arrows-alt" />
    </span>
    <div
      class="sideMenu__option"
    >
      <span
        class="sideMenu__toggler"
        @click="toggleCollapsable"
      />
      <ul
        v-click-outside="hide"
        class="sideMenu__menu"
        v-if="!collapsed"
      >
        <li
          class="sideMenu__menuItem"
          @click="toggleVisibility()"
          v-if="isLogged"
        >
          <i class="fal fa-eye" /> {{ $t('hide') }}
        </li>
        <li class="sideMenu__separator" />
        <li class="sideMenu__subtitle">
          {{ $t('order') }}
        </li>
        <span v-if="options.options.length && options.options.length > 0">
          <li
            v-for="option in options.options"
            :value="option.value"
            :key="option.key"
            :disabled="option.disabled === false ? null : option.disabled"
            class="sideMenu__menuItem"
            :class="sliderParam !== option.value ? '': 'is-active'"
            @click="reorderSlider(option.value)"
          >
            {{ $t(option.i18n) }}
          </li>
        </span>
        <li class="sideMenu__separator" v-if="canCarousel" />
        <li class="sideMenu__subtitle" v-if="canCarousel">
          {{ $t('filter') }}
        </li>
        <li
          @click="filter('')"
          v-if="filterCat !== ''"
          class="sideMenu__menuItem"
        >
          {{ $t('showall') }}
        </li>
        <span v-if="cats && canCarousel">
          <li
            v-for="c in cats"
            :key="c.color"
            @click="filter(c.id)"
            class="sideMenu__menuItem"
            :class="c.id !== filterCat ? '': 'is-active'"
          >
            <span
              class="sideMenu__colorItem"
              :style="`background-color: ${c.color}`"
            /> {{ c.name }}
          </li>
        </span>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import mobileMixin from '@/app/shared/mixins/mobileMixin';

import categories from '../models/categories';

export default defineComponent({
  name: 'SideMenu',
  emits: [
    'carouselDeactivate',
    'carouselActivate',
    'carouselFilter',
  ],
  props: {
    showMore: { type: Boolean, required: false, default: false },
    showLess: { type: Boolean, required: false, default: false },
    options: { type: Object, required: false, default: null },
    canCarousel: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      collapsed: true,
      sliderAction: '',
      sliderKey: '',
      sliderParam: '',
      cats: categories,
      filterCat: '',
    };
  },
  mixins: [mobileMixin],
  computed: {
    ...mapGetters('auth', ['isLogged']),
    ...mapState('user', ['user']),
  },
  methods: {
    ...mapActions('services', [
      'setCardList',
      'setUpStarred',
    ]),
    reorderSlider(param) {
      if (this.user !== null) {
        localStorage.setItem(btoa(this.user.email), JSON.stringify({ key: this.sliderKey, param }));
      }
      this.sliderParam = param;
      this.setCardList({
        type: this.sliderKey,
        param: this.sliderParam,
      });
      this.collapsed = true;
    },
    toggleCollapsable() {
      this.collapsed = !this.collapsed;
    },
    deActivate() {
      this.$emit('carouselDeactivate');
    },
    activate() {
      this.$emit('carouselActivate');
    },
    filter(value) {
      this.filterCat = value;
      this.$emit('carouselFilter', value);
    },
    hide(event) {
      if (!event.target.classList.contains('sideMenu__toggler')) {
        this.collapsed = true;
      }
    },
    toggleVisibility() {
      this.$store.dispatch('services/toggleVisibility', this.sliderKey);
    },
  },
  mounted() {
    this.sliderKey = this.options.sliderKey;
    let storedData = {
      key: '',
      param: '',
    };
    if (this.user !== null) {
      storedData = localStorage.getItem(btoa(this.user.email)) || storedData;
    }
    this.sliderParam = (storedData.key === this.sliderKey && storedData.param !== '')
      ? storedData.param
      : this.options.sliderParam;
    this.sliderAction = this.options.sliderAction;
    const savedFiltered = window.sessionStorage.getItem(`ORDER_${this.sliderKey}`) || '';
    this.filter(savedFiltered);
  },
});
</script>

<i18n src="../locales/i18nSliders.json"></i18n>

<style lang="scss">
  .sideMenu {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media (max-width: $screen-sm-min) {
      top: 0.45rem;
    }
    &__button {
      cursor: pointer;
      display: block;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 2;
      margin: 0 0 0 4px;
      opacity: 1;
      transition: opacity 0.25s linear;
      &:hover {
        opacity: 0.5;
      }
      &.is-movable {
        cursor: move;
        @media (max-width: $screen-lg-min) {
          display: none;
        }
      }
      &.is-clickable {
        cursor: pointer;
      }
    }
    &__toggler {
      background-image: url('/img/icons/dots.svg');
      cursor: pointer;
      display: inline-block;
      width: 32px;
      height: 32px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
      margin: 0 0 0 0;
      transition: opacity 0.25s linear;
      opacity: 1;
      &:hover {
        opacity: 0.5;
      }
    }
    &__menu {
      position: absolute;
      right: -0.5rem;
      top: 1.9rem;
      background: var(--main-bg-color);
      font-size: 0.9rem;
      z-index: 99;
      width: 18rem;
      margin: 0.3rem 0.5rem 0 -13.6rem;
      list-style: none;
      padding: 0 0 0 0;
      z-index: 101;
      max-height: var(--carousel-height);
      overflow: auto;
      box-shadow: var(--shadowLevel-2);
    }
    &__menuItem {
      cursor: pointer;
      padding: 0.9rem 1rem 0.9rem 1.2rem;
      &:hover {
        background-color: #F0F0F0;
      }
      &.is-active {
        background-color: #f5f5f5;
      }
      &.is-active:hover {
        background-color: #f0f0f0;
      }
      i {
        margin: 0 1rem 0 0rem;
      }
    }
    &__subtitle {
      display: block;
      font-size: 0.625rem;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0.875rem 1rem 0.5rem 1.3rem;
    }
    &__separator {
      border-bottom: 1px solid #C0C0C0;
      margin: 8px 0 4px 0;
    }
    &__colorItem {
      border-radius: 3rem;
      display: inline-block;
      height: 1.2rem;
      vertical-align: middle;
      width: 1.2rem;
      position: relative;
      margin: 0 1rem 0 0;
    }
  }
</style>
