export default {
  namespaced: true,
  state: {
    personalData: null,
  },
  mutations: {
    SET_PERSONAL_DATA(state, data) {
      state.personalData = data;
    },
  },
};
