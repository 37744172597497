export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My calendar"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
        "prevMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to previous month"])},
        "nextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to next month"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show event list"])}
      },
      "es": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi agenda"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
        "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a las"])},
        "prevMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al mes anterior"])},
        "nextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al mes siguiente"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar lista de eventos"])}
      }
    }
  })
}
