<template>
  <span
    class="buttonStarred"
    v-if="serviceId && isLogged"
    :title="fillTitleInfo"
  >
    <span
      class="buttonStarred__link"
      :class="activeClass"
      @click.prevent="toggleStarred()"
      @keyup.enter="toggleStarred"
      @keyup.space="toggleStarred"
      tabindex="0"
    >
      <i
        v-if="isStarred"
        class="fas fa-heart"
      />
      <i
        v-else
        class="fal fa-heart"
      />
    </span>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonStarred',
  components: {},
  props: {
    serviceId: { type: String, default: '' },
    serviceName: { type: String, required: true },
  },
  data() {
    return {
      sliderKey: 'STARRED_SERVICES',
    };
  },
  methods: {
    toggleStarred() {
      if (!this.isImpersonating) {
        const datosAMandar = {
          serviceId: this.serviceId,
        };
        this.$store.dispatch('services/toStarred', datosAMandar);
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isLogged', 'isImpersonating']),
    ...mapState('user', ['user']),
    ...mapState('services', ['areas']),
    isStarred() {
      const allStarredIHave = this.areas.STARRED_SERVICES;
      return allStarredIHave.services
        .filter((x) => x.identifier === this.serviceId).length > 0;
    },
    activeClass() {
      return this.isStarred ? 'buttonStarred__is_active' : '';
    },
    fillTitleInfo() {
      return !this.isStarred
        ? `${this.$t('highlight')} ${this.serviceName}`
        : `${this.$t('remove')} ${this.serviceName} ${this.$t('favourites')}`;
    },
  },
});
</script>

<i18n>
{
  "en": {
     "highlight": "Highlight",
     "remove": "Remove",
     "favourites": "from shortcuts"
  },
  "es": {
    "highlight": "Destacar",
    "remove": "Eliminar",
    "favourites": "de favoritos"
  }
}
</i18n>

<style lang="scss" scoped>
.buttonStarred {
  &__link {
    color: var(--title-color);
    font-size: 1rem;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    transform: scale(1);
    opacity: 1;
    transform-origin: 50% 50%;
    transition: opacity 0.25s linear;
    position: relative;
    top: 1px;
    z-index: 9999;
    &:hover {
      opacity: 0.75;
    }
  }
  &__is_active{
    color: var(--main-color);
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
