import auth from './auth/store/modules/auth';
import pages from './shared/store/modules/pages';
import modals from './shared/store/modules/modals';
import language from './shared/store/modules/language';
import feedback from './shared/store/modules/feedback';
import notifications from './shared/store/modules/notifications';
import user from './user/store/modules/user';
import services from './campus-virtual/portal-servicios/store/modules/services';
import acadeConvocatoriaIncidencias from './acade/convocatoria-incidencias/store/solicitud';
import acadeGenerico from './acade/shared/store/acade';
import acadeMtoPreciosPublicos from './acade/mantenimiento-precios-publicos/store/subsanacion';
import acadeFormularioDinamico from './acade/shared/formularioDinamico/store/solicitud';
import acadeReconocimientoCreditos from './acade/reconocimiento-creditos/store/solicitud';
import pau from './acade/traslado-pau/store/pau';
import pauSubsanacion from './acade/traslado-pau/store/solicitud';
import acadeTrasladoExpedienteEntrante from './acade/traslado-expediente-entrada/store/solicitud';
import acadeFamiliaNumerosa from './acade/shared/familiaNumerosa/store/familiaNumerosa';
import matricula from './acade/mi-matricula/store/modules/matricula';
import gesteconRecibos from './gestecon/mis-recibos/store/recibos';
import misCrau from './acade/mis-crau/store/miscrau.store';
import cambiogrupo from './acade/cambio-grupo/store/modules/cambiogrupo';
import eadminMistramites from './eadmin/mis-tramites/store/mistramites';
import consultaAccionesFormativas from './curie/acciones-formativas/store/accionesformativas';
import bonoUniversitario from './pci/transporte/store/modules/bonoUniversitario';
import acadeMatriculaParcial from './acade/matricula-parcial/store/solicitud';
import acadeBajaParcialMatricula from './acade/baja-parcial-matricula/store/solicitud';
import miMovilidad from './curie/movilidad-servicios/store/mimovilidad.store';
import nominas from './gente/nominas/store/nominas.store';
import profile from './user/store/modules/profile.store';

export default {
  auth,
  pages,
  modals,
  language,
  feedback,
  notifications,
  user,
  services,
  acadeConvocatoriaIncidencias,
  acadeGenerico,
  acadeMtoPreciosPublicos,
  acadeFormularioDinamico,
  acadeReconocimientoCreditos,
  pau,
  pauSubsanacion,
  acadeTrasladoExpedienteEntrante,
  acadeFamiliaNumerosa,
  matricula,
  gesteconRecibos,
  misCrau,
  cambiogrupo,
  eadminMistramites,
  bonoUniversitario,
  acadeMatriculaParcial,
  acadeBajaParcialMatricula,
  miMovilidad,
  nominas,
  profile,
  consultaAccionesFormativas,
};
