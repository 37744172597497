<template>
  <div class="fwjsUserProfilePanel">
    <div
      v-if="isLogged"
      @click="toggle"
      @keyup.enter="toggle"
      @keyup.space="toggle"
      tabindex="0"
      class="fwjsUserProfilePanel__outerUserPicture"
      :class="opened ? 'is-active' : ''"
    >
      <span class="fwjsUserProfilePanel__userPicture">
        <img
          v-auth-image="urlPhoto"
          :alt="userCompleteName"
          class="fwjsUserProfilePanel__userAuthImage"
        />
      </span>
    </div>
  </div>
  <Sidebar v-model:visible="opened" position="right">
    <template #header>
      <span @click="hide">
        <FwjsUserData :user="user" :url-photo="urlPhoto" />
      </span>
    </template>
    <nav
      class="fwjsUserProfilePanel__itemsContainer"
      v-if="hasDefaultSlot && shouldRenderChildren"
    >
      <div class="fwjsUserProfilePanel__items" @click="hide">
        <slot />
      </div>
    </nav>
    <router-link
      to="/profile/data"
      class="fwjsUserProfilePanel__menuitem fwjsUserProfilePanel__menuProfile"
      :class="classMenu"
    >
      <span @click="hide" class="fwjsUserProfilePanel__menunative">
        <i class="fal fa-user-circle" /> {{ $t("profile") }}
      </span>
    </router-link>
    <router-link
      to="/preferences"
      class="fwjsUserProfilePanel__menuitem fwjsUserProfilePanel__menuPreferences"
      @click="hide"
    >
      <span @click="hide" class="fwjsUserProfilePanel__menunative">
        <i class="fal fa-cog" /> {{ $t("preferences") }}
      </span>
    </router-link>
    <div
      class="fwjsUserProfilePanel__menuitem"
      @click="logout"
      @keyup.enter="logout"
      @keyup.space="logout"
      tabindex="0"
    >
      <i class="fal fa-power-off" /> {{ $t("logout") }}
    </div>
  </Sidebar>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import clickOutside from '@/app/shared/directives/clickOutside';
import Sidebar from 'primevue/sidebar';
import FwjsUserData from './fwjsUserData.vue';
import userApi from '../user.api';

export default defineComponent({
  name: 'FwjsUserProfile',
  props: {
    shouldRenderChildren: { type: Boolean, default: true },
  },
  components: {
    FwjsUserData,
    Sidebar,
  },
  computed: {
    ...mapGetters('auth', ['isLogged', 'urlLogin']),
    ...mapState('user', ['user']),
    userCompleteName() {
      if (!this.user) {
        return '';
      }
      const { nombre, apellido1, apellido2 } = this.user.afiliacion;
      return `${nombre} ${apellido1} ${apellido2}`;
    },
    urlPhoto() {
      const urls = userApi.endpoints();
      return `${urls.environmentURL}${urls.userPhoto}`;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    classMenu() {
      return this.hasDefaultSlot && this.shouldRenderChildren
        ? ''
        : 'has_noMenu';
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  directives: {
    clickOutside,
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      if (this.$refs && this.$refs.profileMenu) {
        this.$refs.profileMenu.style.display = this.$refs.profileMenu.style.display === 'none' ? 'flex' : 'none';
      }
    },
    hide() {
      this.opened = false;
      if (this.$refs && this.$refs.profileMenu) {
        this.$refs.profileMenu.style.display = 'none';
      }
    },
    logout() {
      if (this.isLogged) {
        this.hide();
        this.$store.dispatch('auth/revokeToken');
      }
    },
    getUserData() {
      if (this.isLogged) {
        this.$store.dispatch('user/setUser');
        this.$store.dispatch('notifications/getNotifications');
      }
    },
  },
  mounted() {
    this.getUserData();
  },
});
</script>

<i18n>
  {
    "en": {
      "login": "Sign in",
      "language": "Choose your language",
      "actions": "Actions",
      "logout": "Logout",
      "profile": "My profile",
      "preferences": "My preferences"
    },
    "es": {
      "login": "Acceder",
      "actions": "Acciones",
      "language": "Elige tu idioma",
      "logout": "Salir",
      "profile": "Mi perfil",
      "preferences": "Mis preferencias"
    }
  }
</i18n>

<style lang="scss">
.p-sidebar-header:has(.fwjsUserData):hover {
  background-color: var(--main-bg-color-dark);
}

.p-sidebar-right:has(.fwjsUserData) {
  min-width: 24rem;
  max-width: 90vw;
  .p-sidebar-content:has(.fwjsUserProfilePanel__itemsContainer) {
    padding: 0 0 1rem 0;
  }
}

.fwjsUserProfilePanel {
  margin: 0 0.5rem;
  user-select: none;

  &__outerUserPicture {
    padding: 0.25rem;
    background: transparent;
    border-radius: 0.25rem;

    &.is-active {
      background: rgba(255, 255, 255, 0.25);
    }
  }

  &__userPicture {
    background-color: transparent;
    border-radius: var(--icon-size);
    cursor: pointer;
    display: block;
    height: var(--icon-size);
    overflow: hidden;
    width: var(--icon-size);

    img {
      height: auto;
      width: var(--icon-size);
    }
  }

  &__menu {
    background: #fff;
    box-shadow: var(--shadowLevel-3);
    color: var(--main-text-color);
    position: fixed;
    right: 0;
    bottom: 0;
    text-align: left;
    top: var(--header-size);
    width: 29rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;

    &.has_noMenu {
      bottom: auto;
    }

    @media (max-width: $screen-sm-min) {
      bottom: 0;
      width: 100vw;
    }
  }

  &__menunative {
    display: block;
  }

  &__itemsContainer {
    flex-grow: 2;
    position: relative;
    max-height: calc(100vh - var(--header-size));
  }

  &__menuitem {
    background: var(--main-bg-color);
    border-top: 1px solid #d8dde1;
    color: #555;
    cursor: pointer;
    display: block;
    padding: 1rem;
    text-decoration: none;

    i {
      margin: 0 1rem 0 0;
    }

    &.is-compact {
      border-top: 0 none;
      padding: 0;
    }

    &.is-small {
      font-size: 0.875rem;
    }

    &.has_noMenu {
      border-top: 0 none;
    }

    &:hover {
      background: var(--main-bg-color-dark);
    }
  }
}
</style>
