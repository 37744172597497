import { defineAsyncComponent } from 'vue';

const MiMatricula = defineAsyncComponent(() => import(/* webpackChunkName: "MiMatricula" */'./views/MiMatricula.vue'));

export default [
  {
    path: '/mi-matricula',
    name: 'MisMatriculas',
    component: MiMatricula,
    meta: {
      main: true,
      requiresAuth: true,
      group: 'acade',
      application: 'mi-matricula',
    },
  },
];
