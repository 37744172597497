import { mapGetters } from 'vuex';

const modalsMixin = {
  data() {
    return {
      modal: null,
      showing: false,
    };
  },
  computed: {
    ...mapGetters('modals', ['couldShow', 'isShowing', 'isShowingAnother']),
    shouldShow() {
      return true;
    },
  },
  methods: {
    tryShowModal(additionalShowCondition, forceDismiss = false) {
      // Calculamos la condición adicional
      let showCondition = additionalShowCondition === undefined;
      if (additionalShowCondition && additionalShowCondition instanceof Function) {
        showCondition = additionalShowCondition();
      } else if (additionalShowCondition) {
        showCondition = !!additionalShowCondition;
      }

      // Si no se puede mostrar, se cierra ese modal
      if (!showCondition) {
        this.$store.commit('modals/CLOSE_MODAL', this.modal);
        if (forceDismiss) {
          this.$store.commit('modals/DISMISS_MODAL', this.modal);
        }
        this.showing = false;
        return;
      }

      // Si se puede mostrar, se registra
      this.couldShow(this.modal).then((could) => {
        if (could) {
          this.$store.commit('modals/SHOW_MODAL', this.modal);
          this.showing = this.isShowing(this.modal);
        }
      });
    },
    isShowingAnotherModal() {
      return this.isShowingAnother(this.modal);
    },
    closeModal() {
      this.$store.commit('modals/CLOSE_CURRENT_MODAL');
      this.showing = false;
    },
  },
  created() {
    this.$store.commit('modals/REGISTER_MODAL', this.modal);
  },
  mounted() {
    this.tryShowModal(this.shouldShow);
  },
};

export { modalsMixin as default };
