export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My calendar"])},
        "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events within the next 30 days"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No events found"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading events"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show calendar"])}
      },
      "es": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi agenda"])},
        "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos en los próximos 30 días"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado eventos"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando eventos"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Calendario"])}
      }
    }
  })
}
