import { createRouter, createWebHashHistory } from 'vue-router';
import storage from '@/storage';
import store from './store';
import routes from './app/app.routes';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  if (to.name !== 'login') {
    storage.setItem('POSE_LOGIN_ROUTE_KEY', to.path);
  }
});

router.beforeEach(async (to, from, next) => {
  storage.setItem('POSE_LOG_GROUP', to.meta.group || '');
  storage.setItem('POSE_LOG_APPLICATION', to.meta.application || '');

  /*
    Se prueba incluyendo `needs2FA: true` en el meta de cualquier ruta
    (por ejemplo en el detail:
    src\app\campus-virtual\portal-servicios\module.routes.js)
  */
  if (to.matched.some(
    (record) => record.meta.requiresAuth && record.meta.needs2FA,
  )) {
    if (!store.getters['auth/valid2FA']) {
      storage.setItem('POSE_FORBIDDEN2FA_ROUTE_KEY', to.path);
      store.commit('auth/SET_2FA_NEEDED', true);
      next('/forbidden2FA');
      return;
    }
  }

  if (to.matched.some((record) => record.meta.hiddenFeature)) {
    const featureFlagActive = storage.getItem('featureFlagActive');
    if (!featureFlagActive) {
      next('/404');
      return;
    }
  }

  if (to.matched.some((record) => record.meta.registroFehaciente)) {
    storage.setItem('REGISTRO_FEHACIENTE', 'fehaciente');
  } else {
    storage.removeItem('REGISTRO_FEHACIENTE');
  }

  /*
  Se verifica que se cumple el criterio establecido.
 */
  if (to.matched.some((record) => record.meta.verify)) {
    const { verify } = to.matched.find((record) => record.meta.verify).meta;
    if (!await verify(to, from, next)) {
      next('/404');
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.commit('pages/PUBLIC', false);
    const accesstoken = storage.getItem('accesstoken');
    // Safari stuff
    const shouldNavigate = !window._env_.isSafari;
    const waitTime = shouldNavigate && accesstoken ? 50 : 1000;
    window.setTimeout(() => {
      if (store.getters['auth/isLogged']) {
        /*
          Necesarias dos cosas para el "modo kiosco":
          poner standalone: true, en las rutas correspondientes.
          Como si fuera el título, también hay que añadir en el mounted
          this.$store.commit('pages/SET_STANDALONE', true);
        */
        store.commit('pages/SET_STANDALONE', to.meta.standalone || false);
      } else {
        if (to.name !== 'login') {
          storage.setItem('POSE_LOGIN_ROUTE_KEY', to.path);
        }
        if (shouldNavigate) {
          store.dispatch('auth/login');
        }
      }
    }, waitTime);
  } else {
    store.commit('pages/PUBLIC', true);
  }

  // Se hace la navegación
  next();
});

export default router;
